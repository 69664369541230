import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as layoutActions from '../../actions/layoutActions';
import * as manageUserActions from "../../actions/manageUserActions";
import * as authenticationActions from "../../actions/authenticationActions";
import {Link, withRouter} from 'react-router-dom';
import Toastr from "toastr";
import {createSelectListObject} from "../../components/shared/selectListHelpers";
import PushFocusToElement from "../../components/common/pushFocusToElement";

import SearchUserForm from "../../components/admin/searchUserForm";
import SearchUserResults from "../../components/admin/searchUserResults";
import * as userConstants from "../../constants/userConstants";
import * as config from "../../constants/config";
import Button from "../../components/common/buttons/button";

export class SearchUserPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            generalProfileData: {
                districts: [],
                roles: []
            },
            isImpersonating: false
        };

        this.searchUsers = this.searchUsers.bind(this);
        this.clearCriteria = this.clearCriteria.bind(this);
        this.order = this.order.bind(this);
        this.first = this.first.bind(this);
        this.last = this.last.bind(this);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.details = this.details.bind(this);
        this.impersonate = this.impersonate.bind(this);
        this.returnAsAdmin = this.returnAsAdmin.bind(this);
        this.onCriteriaChange = this.onCriteriaChange.bind(this);
    }

    componentWillMount() {
        this.props.actions.updatePageTitle("Search for a User");
        if(this.props.searchCriteria.searchPerformed) {
            this.props.actions.loadUsers(this.props.searchCriteria)
                .catch(error => {
                    if(error !== "")
                        Toastr.error(error);
                });
        }

        this.updateGeneralProfileData(this.props);
    }

    componentWillReceiveProps(newProps){
        this.updateGeneralProfileData(newProps);
    }

    updateGeneralProfileData(newProps) {
        let allLoaded = true;
        let needsLoading = false;
        const generalProfileData = this.state.generalProfileData;
        ["roles", "districts"].map( generalDataName => {
            if (this.state.generalProfileData[generalDataName].length === 0 &&
                newProps.generalProfileData &&
                newProps.generalProfileData[generalDataName].length > 0) {
                const selectListItems = newProps.generalProfileData[generalDataName].map(
                    item => createSelectListObject(generalDataName, item)
                );

                needsLoading = true;

                generalProfileData[generalDataName] = selectListItems;
            }
            else if(this.state.generalProfileData[generalDataName].length === 0)
                allLoaded = false;
        });
        if(needsLoading) {
            this.setState({generalProfileData: generalProfileData});

            if (allLoaded)
                PushFocusToElement("NameEmailContains");
        }
    }

    onCriteriaChange(event) {
        const userSearchCriteria = Object.assign({}, this.props.searchCriteria);
        const name = event.target.name;

        userSearchCriteria[name] = event.target.value;

        this.props.actions.saveSearchCriteria(userSearchCriteria);
    }

    searchUsers(event) {
        event.preventDefault();

        let criteria = Object.assign({}, this.props.searchCriteria);
        criteria.Page = 1;

        this.loadUsers(criteria);
    }

    clearCriteria(event) {
        event.preventDefault();

        this.loadUsers(userConstants.generateEmptyUserSearchCriteria());
    }

    order(event) {
        event.preventDefault();

        const target = event.target;

        let criteria = Object.assign({}, this.props.searchCriteria);

        if (criteria.OrderByColumn === target.innerHTML) {
            criteria.OrderDescending = !criteria.OrderDescending;
        }
        else {
            criteria.OrderDescending = false;
            criteria.OrderByColumn = target.innerHTML;
        }

        this.loadUsers(criteria);
    }

    first(event) {
        event.preventDefault();

        let criteria = Object.assign({}, this.props.searchCriteria);
        criteria.Page = 1;

        this.loadUsers(criteria);
    }

    last(event) {
        event.preventDefault();

        const totalRecords = this.props.totalRecords;

        const lastPage = Math.ceil(totalRecords / this.props.searchCriteria.RecordsPerPage);

        let criteria = Object.assign({}, this.props.searchCriteria);
        criteria.Page = lastPage;

        this.loadUsers(criteria);
    }

    next(event) {
        event.preventDefault();

        let criteria = Object.assign({}, this.props.searchCriteria);
        criteria.Page = criteria.Page + 1;

        this.loadUsers(criteria);
    }

    previous(event) {
        event.preventDefault();

        let criteria = Object.assign({}, this.props.searchCriteria);
        criteria.Page--;
        this.loadUsers(criteria);
    }

    loadUsers(searchCriteria) {
        searchCriteria.searchPerformed = true;
        this.props.actions.saveSearchCriteria(searchCriteria);

        this.props.actions.loadUsers(searchCriteria)
            .catch(error => {
                if(error !== "")
                    Toastr.error(error);
            });
    }

    details(event) {
        event.preventDefault();
        this.props.history.push("/admin/user/" + event.target.parentNode.id);
    }

    impersonate(event) {
        event.preventDefault();
        this.props.actions.loginAsUser(parseInt(event.target.parentNode.id))
            .then(() => {
                this.setState({isImpersonating: true});
                this.viewUserHomeInNewWindow();
            })
            .catch(error => {
                if(error !== "")
                    Toastr.error(error);
            });
    }

    viewUserHomeInNewWindow() {
        let redirectStr = (process.env.NODE_ENV === 'production') ? config.courseLandingPage : `/#${config.courseLandingPage}`;
        if(this.props.authentication.isAdmin)
            redirectStr = `/#${config.adminLandingPage}`;

        window.open(redirectStr);
    }

    returnAsAdmin() {
        this.props.actions.refreshAdminLogin()
            .then(() => {
                this.setState({isImpersonating: false});
            })
            .catch(error => {
                if(error !== "")
                    Toastr.error(error);
            });
    }

    render() {
        return (
            <div>
                {!this.state.isImpersonating &&
                <div>
                    <p><Link to={config.adminLandingPage}>Return to Admin Menu</Link></p>
                    <p>Specify the options and click &quot;Search&quot;.  Then click on the impersonate icon (<span className={"icon-impersonate"} alt={"Impersonate"} />) to login as
                        that user in a new tab.  Simply close the tab to return to the admin functions.</p>
                    <div>
                        <hr/>
                        <SearchUserForm criteria={this.props.searchCriteria}
                                        generalProfileData={this.state.generalProfileData}
                                        search={this.searchUsers}
                                        clearCriteria={this.clearCriteria}
                                        onFormChange={this.onCriteriaChange}/>
                    </div>
                </div>
                }
                {this.props.searchCriteria.searchPerformed && !this.state.isImpersonating &&
                    <div>
                    <hr />
                        <SearchUserResults users={this.props.users}
                                           first={this.first}
                                           last={this.last}
                                           next={this.next}
                                           previous={this.previous}
                                           details={this.details}
                                           impersonate={this.impersonate}
                                           order={this.order}
                                           totalRecords={this.props.totalRecords}
                                           currentPage={this.props.searchCriteria.Page}
                                           recordsPerPage={this.props.searchCriteria.RecordsPerPage} />
                </div>
                }
                {this.state.isImpersonating &&
                <div className={"text-align-center"}>
                        <Button name={"btnReturnAsAdmin"} label={"Finish impersonating and return as an Admin!"} onClick={this.returnAsAdmin}/>
                </div>}
            </div>
        );
    }
}

SearchUserPage.propTypes = {
    users: PropTypes.arrayOf(PropTypes.object).isRequired,
    searchCriteria: PropTypes.object.isRequired,
    generalProfileData: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    ajaxCallsLoading: PropTypes.bool,
    totalRecords: PropTypes.number,
    authentication: PropTypes.object,
    history: PropTypes.object
};

function mapStateToProps(state) {
    return {
        users: state.user.searchResults,
        searchCriteria: state.user.searchCriteria,
        totalRecords: state.user.totalRecords,
        generalProfileData: state.generalProfileData,
        ajaxCallsLoading: state.ajaxCallsInProgress > 0,
        authentication: state.authentication
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign({}, manageUserActions, layoutActions, authenticationActions);

    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (SearchUserPage));