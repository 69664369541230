import React from "react";
import {Switch} from "react-router-dom";

import "../sass/main.scss";

import CreateProfilePage from "../containers/account/createProfilePage";
import EditProfilePage from "../containers/account/editProfilePage";
import ForgotPasswordPage from "../containers/account/forgotPasswordPage";
import AdminMenuPage from "../containers/admin/adminMenuPage";
import EditUserPage from "../containers/admin/editUserPage";
import SearchUserPage from "../containers/admin/searchUserPage";
import LoginPage from "../containers/authenticate/loginPage";
import CoursePage from "../containers/coursePage";
import NotFoundPage from "../containers/notFoundPage";
import DistrictReportPage from "../containers/reports/districtReportPage";
import SummaryReportPage from "../containers/reports/summaryReportPage";

import DefaultLayout from "../containers/layout/defaultLayout";
import FullWideLayout from "../containers/layout/fullWideLayout";
import {Authorization} from "../containers/authenticate/authorization";

class App extends React.Component {
    render() {
        return (<div>
            <Switch>
                <DefaultLayout exact path={"/"} component={LoginPage}/>
                <DefaultLayout path="/forgotPassword" component={ForgotPasswordPage}/>
                <DefaultLayout path="/createProfile" component={CreateProfilePage}/>

                <DefaultLayout ensureLoggedIn path="/course" component={CoursePage}/>
                <DefaultLayout ensureLoggedIn path="/editProfile" component={EditProfilePage}/>

                <FullWideLayout path="/admin/menu" component={Authorization(AdminMenuPage)}/>
                <FullWideLayout path="/admin/search" component={Authorization(SearchUserPage)}/>
                <FullWideLayout path="/admin/user/:userId" component={Authorization(EditUserPage)}/>
                <FullWideLayout path="/reports/summaryReport" component={Authorization(SummaryReportPage)}/>
                <FullWideLayout path="/reports/districtReport" component={Authorization(DistrictReportPage)}/>

                <DefaultLayout component={NotFoundPage}/>
            </Switch>
        </div>);
    }
}

export default App;