import React from 'react';
import PropTypes from "prop-types";
import ViewDetails from "../common/viewDetails";

const ViewUserForm = ({  accountViewModel, generalProfileData }) => {

    return (
        <div>
            <ViewDetails
                label="First Name:"
                value={accountViewModel.FirstName}
            />

            <ViewDetails
                label="Last Name:"
                value={accountViewModel.LastName}
            />

            <ViewDetails
                label="Email:"
                value={accountViewModel.Email}
            />

        </div>
    );
};

ViewUserForm.propTypes = {
    accountViewModel: PropTypes.object.isRequired,
    generalProfileData: PropTypes.object.isRequired
};

export default ViewUserForm;
