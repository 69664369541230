import * as userConstants from "../constants/userConstants";
import * as reportConstants from "../constants/reportConstants";

export default {
    ajaxCallsInProgress: 0,
    authentication: {
        isLoggedIn: false,
        redirectUrl: "",
        isAdmin: false,
        role: "user"
    },
    account: {
        emailExists: false,
        Id: 0,
        Email: "",
        FirstName: "",
        LastName: "",
        Password: ""
    },
    layout: {
        pageTitle: "",
        subTitle: ""
    },
    generalProfileData: {
        roles: [],
        courses: [],
        districts: [],
    },
    user: {
        searchCriteria: userConstants.generateEmptyUserSearchCriteria(),
        totalRecords: 0,
        searchResults: []
    },
    districtReport: {
        searchCriteria: reportConstants.generateEmptyDistrictSearchCriteria(),
        totalRecords: 0,
        searchResults: []
    },
    summaryReport: {
        searchCriteria: reportConstants.generateEmptySummarySearchCriteria(),
        summaryResults: {}
    }
};
