import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import {withRouter} from "react-router-dom";
import Toastr from "toastr";
import ToastrOptions from "../../constants/toastr";
import ProfileFormIsValid from "../../components/account/userValidation";
import PushFocusToElement from "../../components/common/pushFocusToElement";

import * as accountActions from "../../actions/accountActions";
import * as authenticationActions from "../../actions/authenticationActions";
import * as layoutActions from "../../actions/layoutActions";
import * as config from "../../constants/config";
import {CheckboxType, RadioType, SelectListType, adjustDelimitedList, createRedirectUrl} from "../../components/common/htmlUtilities";
import EditProfile from '../../components/account/profile';
import {createSelectListObject} from "../../components/shared/selectListHelpers";

export class EditProfilePage extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            accountViewModel: { },
            isLoading: false,
            errors: {},
            generalProfileData: {
                districts: []

            }
        };

        Toastr.options = ToastrOptions.Error();

        this.onEditClick = this.onEditClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
    }

    componentWillMount() {
        this.props.actions.updatePageTitle("Edit Profile");
        const redirectUrl = createRedirectUrl(this.props.location.search, this.props.location.hash);
        if(redirectUrl != "")
            this.props.actions.setRedirectUrl(redirectUrl);

        this.props.actions.loadProfile()
            .then(() => {
                PushFocusToElement("FirstName");
            })
            .catch(error => {
                if(error !== "")
                    Toastr.error(error);
            });

        this.updateGeneralProfileData(this.props);
    }

    componentWillReceiveProps(newProps){
        if(this.state.accountViewModel.Id === undefined ||
            this.props.account !== newProps.account){
            this.setState({
                accountViewModel: Object.assign({}, newProps.account, {UpdatePassword: false, Password: "", VerifyPassword: "" })
            });
        }

        this.updateGeneralProfileData(newProps);
    }

    updateGeneralProfileData(newProps) {
        let allLoaded = true;
        ["districts"].map( generalDataName => {
            if (this.state.generalProfileData[generalDataName].length === 0 &&
                newProps.generalProfileData &&
                newProps.generalProfileData[generalDataName].length > 0) {
                const selectListItems = newProps.generalProfileData[generalDataName].map(
                    item => createSelectListObject(generalDataName, item)
                );

                const generalProfileData = this.state.generalProfileData;
                generalProfileData[generalDataName] = selectListItems;
                this.setState({generalProfileData: generalProfileData});
            }
            else if(this.state.generalProfileData[generalDataName].length === 0)
                allLoaded = false;
        });

        if(allLoaded)
            PushFocusToElement("FirstName");
    }

    onFormChange(e) {
        const accountViewModel = this.state.accountViewModel;
        const name = e.target.name;
        if(e.target.type === CheckboxType) {
            if(name === "UpdatePassword")
                accountViewModel[name] = e.target.checked;
            else {
                const checkboxInfo = name.split("_");
                accountViewModel[checkboxInfo[0]] = adjustDelimitedList(accountViewModel[checkboxInfo[0]], checkboxInfo[1], e.target.checked);
            }

        }
        else if(e.target.type === RadioType || e.target.type === SelectListType){
            accountViewModel[name] = (e.target.value === "") ? null : parseInt(e.target.value);

            if(name === "EmployeeType"){
                accountViewModel.IsDistrictEmployee = null;
                accountViewModel.IsOther = null;

                switch(accountViewModel[name]) {
                    case 1:
                        accountViewModel.IsDistrictEmployee = 1;
                        break;
                    case 2:
                        accountViewModel.IsOther = 1;
                        accountViewModel.DistrictId = null;
                        break;

                }
            }
        }
        else
            accountViewModel[name] = e.target.value;

        this.setState({ accountViewModel: accountViewModel });
    }

    onEditClick(e) {
        e.preventDefault();

        let errors = {};
        let formIsValid = ProfileFormIsValid(this.state.accountViewModel, errors);
        this.setState({errors: errors});
        if (!formIsValid) {
            Toastr.error("Please correct the issues as specified and try logging in again.");
            return;
        }

        this.setState({ isLoading: true });
        this.props.actions.updateProfile(this.state.accountViewModel)
            .then(() => {
                Toastr.success("Your profile has been updated!");
                this.setState({isLoading: false});
            })
            .catch(error => {
                if( error.errors )
                    this.setState({errors: error.errors});

                if(error.toastrMsg && error.toastrMsg !== "")
                    Toastr.error(error.toastrMsg);

                this.setState({isLoading: false});
            });
    }

    redirect() {
        this.setState({isLoading: false});
        const {redirectUrl} = this.props.authentication;
        let redirectStr = (redirectUrl !== "") ? redirectUrl : config.courseLandingPage;
        if (process.env.NODE_ENV === 'production')
            window.location.href = redirectStr;
        else
            this.props.history.push(redirectStr);
    }

    onCancelClick(e) {
        e.preventDefault();

        this.redirect();
    }

    render() {
        return (
            <EditProfile
                isCreate={false}
                accountViewModel={this.state.accountViewModel}
                generalProfileData={this.state.generalProfileData}
                pageDirections="Edit your profile and click <strong>Update</strong> below."
                onClick={this.onEditClick}
                onCancel={this.onCancelClick}
                onChange={this.onFormChange}
                isLoading={this.state.isLoading}
                ajaxCallsLoading={this.props.ajaxCallsLoading}
                errors={this.state.errors}
            />
        );
    }
}

EditProfilePage.propTypes = {
    actions: PropTypes.object.isRequired,
    account: PropTypes.object,
    authentication: PropTypes.object,
    generalProfileData: PropTypes.object,
    ajaxCallsLoading: PropTypes.bool,
    history: PropTypes.object.isRequired,
    location: PropTypes.object
};

function mapStateToProps(state) {
    return {
        account: state.account,
        authentication: state.authentication,
        generalProfileData: state.generalProfileData,
        ajaxCallsLoading: state.ajaxCallsInProgress > 0
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        accountActions,
        authenticationActions,
        layoutActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (EditProfilePage));
