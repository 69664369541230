import reportApi from "../api/reportApi";
import { beginAjaxCall, ajaxCallError } from "./ajaxStatusActions";
import * as actionTypes from "../constants/actionTypes";
import * as config from "../constants/config";
import {refreshLogin} from "./authenticationActions";

export function loadDistrictDataSuccess(data){
    return { type: actionTypes.LOAD_DISTRICT_DATA_AJAX_SUCCESS, data };
}

export function loadDistrictData(filterCriteria){
    return dispatch => {
        dispatch(beginAjaxCall());
        return reportApi.getDistrictData(filterCriteria)
            .then(data =>
            {
                dispatch(loadDistrictDataSuccess(data));
            })
            .catch(error =>
                {
                    if(config.useRefreshToken === error) {
                        dispatch(refreshLogin())
                            .then(() => {
                                dispatch(loadDistrictData(filterCriteria));
                            });
                    }
                    else {
                        dispatch(ajaxCallError(error));
                        throw (error);
                    }
                }
            );
    };
}

export function saveDistrictReportSearchCriteria(criteria) {
    return { type: actionTypes.SAVE_DISTRICT_REPORT_SEARCH_CRITERIA, criteria};
}


export function exportDistrictData(filterCriteria) {
    return () => {
        reportApi.exportDistrictData(filterCriteria);
    };
}

export function loadSummaryDataSuccess(data){
    return { type: actionTypes.LOAD_SUMMARY_REPORT_DATA_AJAX_SUCCESS, data };
}

export function loadSummaryData(filterCriteria){
    return dispatch => {
        dispatch(beginAjaxCall());

        return reportApi.getSummaryReportData(filterCriteria)
            .then(data =>
            {
                dispatch(loadSummaryDataSuccess(data));
            })
            .catch(error =>
                {
                    if(config.useRefreshToken === error) {
                        dispatch(refreshLogin())
                            .then(() => {
                                dispatch(loadSummaryData(filterCriteria));
                            });
                    }
                    else {
                        dispatch(ajaxCallError(error));
                        throw (error);
                    }
                }
            );
    };
}

export function saveSummaryReportSearchCriteria(criteria) {
    return { type: actionTypes.SAVE_SUMMARY_REPORT_SEARCH_CRITERIA, criteria};
}
