import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import {withRouter} from "react-router-dom";
import * as layoutActions from '../../actions/layoutActions';
import * as manageUserActions from "../../actions/manageUserActions";
import Toastr from "toastr";
import {getParams} from "../../components/shared/getParams";
import ToastrOptions from "../../constants/toastr";
import PushFocusToElement from "../../components/common/pushFocusToElement";
import ProfileFormIsValid from "../../components/account/userValidation";
import {createSelectListObject} from "../../components/shared/selectListHelpers";
import {CheckboxType, RadioType, SelectListType, adjustDelimitedList} from "../../components/common/htmlUtilities";
import EditUserForm from '../../components/admin/editUser';
import ViewUserForm from "../../components/admin/viewUser";
import * as PolicyEvents from "../../constants/policyEvents";
import Allow from "../authenticate/allow";

export class EditUserPage extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            accountViewModel: { },
            isLoading: false,
            errors: {},
            generalProfileData: {
                roles: [],
                courses: [],
                districts: []
            }
        };

        Toastr.options = ToastrOptions.Error();

        this.onEditClick = this.onEditClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
    }

    componentWillMount() {
        this.props.actions.updatePageTitle("Edit User");
        const params = { userId: ":userId" };
        const paramObj = getParams(this.props.location.pathname, "/admin/user/:userId", params);
        this.props.actions.loadUser(paramObj.userId)
            .then(() => {
                PushFocusToElement("FirstName");
            })
            .catch(error => {
                if(error !== "")
                    Toastr.error(error);
            });

        this.updateGeneralProfileData(this.props);
    }

    componentWillReceiveProps(newProps){
        if(this.state.accountViewModel.Id === undefined ||
            this.props.account !== newProps.account){
            this.setState({
                accountViewModel: Object.assign({}, newProps.account, {UpdatePassword: false, Password: "", VerifyPassword: "" })
            });
        }

        this.updateGeneralProfileData(newProps);
    }

    updateGeneralProfileData(newProps) {
        let allLoaded = true;
        const generalProfileData = this.state.generalProfileData;

        if (this.state.generalProfileData.courses.length === 0 &&
            newProps.generalProfileData &&
            newProps.generalProfileData.courses.length > 0) {

            generalProfileData.courses = newProps.generalProfileData.courses;
        }
        else if(this.state.generalProfileData.courses.length === 0)
            allLoaded = false;

        ["roles", "districts"].map( generalDataName => {
            if (this.state.generalProfileData[generalDataName].length === 0 &&
                newProps.generalProfileData &&
                newProps.generalProfileData[generalDataName].length > 0) {
                const selectListItems = newProps.generalProfileData[generalDataName].map(
                    item => createSelectListObject(generalDataName, item)
                );

                generalProfileData[generalDataName] = selectListItems;
            }
            else if(this.state.generalProfileData[generalDataName].length === 0)
                allLoaded = false;
        });
        this.setState({generalProfileData: generalProfileData});

        if(allLoaded)
            PushFocusToElement("FirstName");
    }

    onFormChange(e) {
        const accountViewModel = this.state.accountViewModel;
        const name = e.target.name;
        if(e.target.type === CheckboxType) {
            if(name === "UpdatePassword")
                accountViewModel[name] = e.target.checked;
            else {
                const checkboxInfo = name.split("_");
                accountViewModel[checkboxInfo[0]] = adjustDelimitedList(accountViewModel[checkboxInfo[0]], checkboxInfo[1], e.target.checked);
            }
        }
        else if(e.target.type === RadioType || e.target.type === SelectListType){
            accountViewModel[name] = (e.target.value === "") ? null : parseInt(e.target.value);

            if(name === "EmployeeType"){
                accountViewModel.IsDistrictEmployee = null;
                accountViewModel.IsOther = null;

                switch(accountViewModel[name]) {
                    case 1:
                        accountViewModel.IsDistrictEmployee = 1;
                        break;
                    case 2:
                        accountViewModel.IsOther = 1;
                        accountViewModel.DistrictId = null;
                        break;

                }
            }
        }

        else
            accountViewModel[name] = e.target.value;

        this.setState({ accountViewModel: accountViewModel });
    }

    onDateChange(selectedDates, dateStr, instance) {
        const accountViewModel = this.state.accountViewModel;
        const courseInfo = instance.config.class.split(';');

        dateStr = (dateStr === "") ? null : dateStr;

        if(dateStr === null)
            accountViewModel[courseInfo[0] + "CompletionDate"] = dateStr;

        accountViewModel[courseInfo[1] + "CompletionDate"] = dateStr;
        this.setState({ accountViewModel: accountViewModel });
    }

    CourseDataIsValid(accountViewModel, errors, allCourses) {
        let overallOkay = true;
        allCourses.map((course) => {
            let overallDate = null;
            if (accountViewModel[course.Acronym + "CompletionDate"] !== null)
                overallDate = accountViewModel[course.Acronym + "CompletionDate"];

            let allSectionsComplete = true;

            course.SectionIds.map((sectionId) => {
                let selectedDate = null;
                if (accountViewModel[sectionId + "CompletionDate"] !== null)
                    selectedDate = accountViewModel[sectionId + "CompletionDate"];

                if (selectedDate === null)
                    allSectionsComplete = false;
            });

            if(allSectionsComplete && overallDate === null) {
                errors[course.Acronym + "CompletionDate"] = "Overall completion date for " + course.CourseName + " is required.";
                overallOkay = false;
            }
        });

        return overallOkay;
    }

    onEditClick(e) {
        e.preventDefault();

        let errors = {};
        let formIsValid = ProfileFormIsValid(this.state.accountViewModel, errors);

        let courseDataIsValid = this.CourseDataIsValid(this.state.accountViewModel, errors, this.state.generalProfileData.courses);

        this.setState({errors: errors});
        if (!formIsValid || !courseDataIsValid) {
            Toastr.error("Please correct the issues as specified.");
            return;
        }

        this.setState({ isLoading: true });
        this.props.actions.updateUser(this.state.accountViewModel, this.state.generalProfileData.courses)
            .then(() => {
                Toastr.success("The user has been updated!");
                this.setState({isLoading: false});
                this.redirect();
            })
            .catch(error => {
                if( error.errors )
                    this.setState({errors: error.errors});
                if(error.toastrMsg && error.toastrMsg !== "")
                    Toastr.error(error.toastrMsg);
                this.setState({isLoading: false});
            });
    }

    redirect() {
        this.setState({isLoading: false});
        this.props.history.push("/admin/search");
    }

    onCancelClick(e) {
        e.preventDefault();

        this.redirect();
    }

    render() {
        return (
            <section>
                <Allow policyEvent={PolicyEvents.MANAGE}>
                    <EditUserForm
                        accountViewModel={this.state.accountViewModel}
                        generalProfileData={this.state.generalProfileData}
                        onClick={this.onEditClick}
                        onCancel={this.onCancelClick}
                        onChange={this.onFormChange}
                        onDateChange={this.onDateChange}
                        isLoading={this.state.isLoading}
                        ajaxCallsLoading={this.props.ajaxCallsLoading}
                        errors={this.state.errors}
                    />
                </Allow>
                <Allow>
                    <ViewUserForm
                        accountViewModel={this.state.accountViewModel}
                        generalProfileData={this.state.generalProfileData}
                        onCancel={this.onCancelClick}
                    />
                </Allow>
            </section>
        );
    }
}

EditUserPage.propTypes = {
    actions: PropTypes.object.isRequired,
    account: PropTypes.object,
    generalProfileData: PropTypes.object,
    ajaxCallsLoading: PropTypes.bool,
    params: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object
};

function mapStateToProps(state) {
    return {
        account: state.account,
        generalProfileData: state.generalProfileData,
        ajaxCallsLoading: state.ajaxCallsInProgress > 0
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        manageUserActions,
        layoutActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (EditUserPage));