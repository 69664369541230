import React from "react";
import PropTypes from "prop-types";

const DistrictReportRow = ({ report }) => {
    return (
        <tr>
            <td>
                {report.LastName}
            </td>
            <td>
                {report.FirstName}
            </td>
            <td>
                {report.Email}
            </td>
            <td>
                {report.District}
            </td>
            <td>
                {report.EmployeeTypeOutput}
            </td>
            <td className="text-align-center">
                {report.CourseName}
            </td>
            <td className="text-align-center">
                {report.CourseCompletionDate}
            </td>
        </tr>
    );
};

DistrictReportRow.propTypes = {
    report: PropTypes.object.isRequired
};

export default DistrictReportRow;