import $ from 'jquery';
import * as config from "../constants/config";
import LocalStorage from "../components/shared/localStorage";
import {
    getAccounts, convertCourseDataToAccountViewModel, convertViewModelToCourseData,
    saveAccounts
} from "./accountData";
import * as PolicyEvents from "../constants/policyEvents";
import {generateEmployeeTypeOptions} from "../constants/userConstants";
import ApiUtility from "./apiUtility";

let delay = 0;
if(process.env.delay)
    delay = process.env.delay;

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerManageUserApi {
    static getUser(userId) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: config.apiUrl + 'users/' + userId,
                beforeSend: function(request) {
                    request.setRequestHeader("Authorization", LocalStorage.getAuthTokenValue());
                },
                crossDomain: true,
                dataType: "json",
                contentType: "application/json",
                type: "GET",
                error: function (data) {
                    new ApiUtility(data, reject).errorResponseHandler();
                },
                success: function (data) {
                    new ApiUtility(convertCourseDataToAccountViewModel(data), resolve).successResponseHandler();
                }
            });
        });
    }

    static getUsers(searchCriteria) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: config.apiUrl + 'users/?' + $.param(searchCriteria),
                beforeSend: function(request) {
                    request.setRequestHeader("Authorization", LocalStorage.getAuthTokenValue());
                },
                crossDomain: true,
                dataType: "json",
                contentType: "application/json",
                type: "GET",
                error: function (data) {
                    new ApiUtility(data, reject).errorResponseHandler();
                },
                success: function (data) {
                    new ApiUtility(data, resolve).successResponseHandler();
                }
            });
        });
    }

    static updateUser(accountViewModel, allCourses) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: config.apiUrl + 'users',
                data: JSON.stringify(convertViewModelToCourseData(accountViewModel, allCourses)),
                beforeSend: function(request) {
                    request.setRequestHeader("Authorization", LocalStorage.getAuthTokenValue());
                },
                crossDomain: true,
                dataType: "json",
                contentType: "application/json",
                type: "PUT",
                error: function (data) {
                    new ApiUtility(data, reject).errorResponseHandler();
                },
                success: function () {
                    new ApiUtility(null, resolve).successResponseHandler();
                }
            });
        });
    }
}


//************************************************** MOCK API SERVER CALLS ****************************************

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.

class MockManageUserApi {
    static getUser(userId) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const userAcct = getAccounts().filter(account => account.Id === parseInt(userId));
                if (userAcct.length === 0)
                    reject("There was an error while attempting to retrieve your account.");
                else {
                    resolve(convertCourseDataToAccountViewModel(userAcct[0]));
                }
            }, delay);
        });
    }

    static getUsers(searchCriteria) {
        return new Promise((resolve) => {
            setTimeout(() => {
                let accounts = [];

                const auth = LocalStorage.getAuthentication();
                if(auth.role === PolicyEvents.VIEWONLY)
                    accounts = getAccounts().filter(account => account.Role === "User");
                else
                    accounts = getAccounts().filter(account => account.Role !== "System Admin");

                if(searchCriteria.NameEmailContains !== "")
                    accounts = accounts.filter(account =>
                        account.Email.includes(searchCriteria.NameEmailContains) ||
                        (account.FirstName + " " + account.LastName).includes(searchCriteria.NameEmailContains));

                let sortProp = "LastName";
                switch (searchCriteria.OrderByColumn)
                {
                    case "Email":
                        sortProp = searchCriteria.OrderByColumn;
                        break;
                    case "First Name":
                        sortProp = "FirstName";
                        break;
                    case "Role":
                        sortProp = "role";
                        break;
                }

                let sortDir = (searchCriteria.OrderDescending) ? -1 : 1;

                accounts.sort(function(a,b) {return (a[sortProp] > b[sortProp]) ? sortDir : ((b[sortProp] > a[sortProp]) ? -1 * sortDir : 0);} );

                const skip = (searchCriteria.Page - 1) * searchCriteria.RecordsPerPage;
                const take = searchCriteria.RecordsPerPage + skip;

                resolve(Object.assign({}, { Data: accounts.slice(skip, take), TotalRecords: accounts.length}));
            }, delay);
        });
    }

    static updateUser(accountViewModel, allCourses) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                let accounts = getAccounts();
                const otherUserAccounts = accounts.filter(account => account.Id !== parseInt(accountViewModel.Id));
                const userAcct = accounts.filter(account => account.Id === parseInt(accountViewModel.Id));
                if (userAcct.length === 0)
                    reject("There was an error while attempting to retrieve your account.");
                else {
                    // get role added to updated record
                    const allRoles = [
                        { RoleId: 1, RoleName: "User" },
                        { RoleId: 2, RoleName: "System Admin" },
                        { RoleId: 3, RoleName: "Admin" },
                        { RoleId: 4, RoleName: "View Only Admin" }
                    ];
                    accountViewModel.Role = allRoles.filter(r => r.RoleId === accountViewModel.RoleId)[0].RoleName;

                    saveAccounts([...otherUserAccounts, convertViewModelToCourseData(accountViewModel, allCourses)]);
                }
                resolve({});
            }, delay);
        });
    }
}

const ManageUserApi = (process.env.NODE_ENV === 'production' || process.env.API_MODE === 'server') ? ServerManageUserApi : MockManageUserApi;
export default ManageUserApi;