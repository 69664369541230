import React from "react";
import PropTypes from "prop-types";
import Allow from "../../containers/authenticate/allow";
import TextField from "../common/inputs/textField";
import SelectField from "../common/inputs/selectField";
import Button from "../common/buttons/button";
import ButtonLink from "../common/buttons/buttonLink";
import ButtonBar from "../common/buttons/buttonBar";
import GridColumn from "../common/gridColumn";
import GridRow from "../common/gridRow";
import {generateEmployeeTypeOptions} from "../../constants/userConstants";
import * as PolicyEvents from "../../constants/policyEvents";

const DistrictReportSearchForm = ({
                                      criteria,
                                      generalProfileData,
                                      onFormChange,
                                      clearCriteria,
                                      generateReport,
                                      errors
                                  }) => {
    const districts = generalProfileData.districts.map(m => {
        return {value: m.value, text: m.text};
    });
    return (
        <form className={"is-print-hidden"}>
            <GridRow rowClass="filter-bar" medium="2" large="4">
                <div>
                    <GridColumn>
                        <TextField name="NameEmailContains"
                                   label="Name or Email:"
                                   onChange={onFormChange}
                                   showLabel
                                   value={criteria.NameEmailContains}
                                   maxLength={100}
                        />
                        <hr className={"spacer"}/>
                    </GridColumn>
                    <Allow policyEvent={PolicyEvents.USE_DISTRICT_ADMIN_FILTERS}>
                        <>
                            <GridColumn>
                                <SelectField name="EmployeeType"
                                             label="Type"
                                             onChange={onFormChange}
                                             showLabel
                                             options={generateEmployeeTypeOptions()}
                                             value={criteria.EmployeeType}
                                             defaultOption={"Any Type"}
                                />
                            </GridColumn>
                            <GridColumn>
                                <SelectField name="DistrictId"
                                             label="District"
                                             onChange={onFormChange}
                                             showLabel
                                             options={districts}
                                             value={criteria.DistrictId ? criteria.DistrictId.toString() : ""}
                                             error={(errors) ? errors.DistrictId : ""}
                                             defaultOption={"Any District"}
                                />
                            </GridColumn>
                        </>
                    </Allow>
                </div>
                <div>
                    <ButtonBar>
                        <Button name="btnGenerateReport" onClick={generateReport} label="Search"/>
                        <ButtonLink name="btnClearCriteria" onClick={clearCriteria} label="Clear"/>
                    </ButtonBar>
                </div>
            </GridRow>
        </form>
    );
};

DistrictReportSearchForm.propTypes = {
    criteria: PropTypes.object.isRequired,
    generalProfileData: PropTypes.object.isRequired,
    onFormChange: PropTypes.func.isRequired,
    clearCriteria: PropTypes.func.isRequired,
    generateReport: PropTypes.func.isRequired,
    errors: PropTypes.object
};

export default DistrictReportSearchForm;