import { combineReducers } from 'redux';
import ajaxCallsInProgress from "./ajaxStatusReducer";
import account from "./accountReducer";
import authentication from "./authenticationReducer";
import layout from "./layoutReducer";
import generalProfileData from "./generalProfileDataReducer";
import user from "./userReducer";
import districtReport from "./districtReportReducer";
import summaryReport from "./summaryReportReducer";

import { connectRouter } from 'connected-react-router';

const rootReducer = (history) => combineReducers({
    account,
    authentication,
    ajaxCallsInProgress,
    layout,
    generalProfileData,
    user,
    districtReport,
    summaryReport,
    router: connectRouter(history)
});

export default rootReducer;