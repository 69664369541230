import $ from 'jquery';
import * as config from "../constants/config";
import LocalStorage from "../components/shared/localStorage";
import { getDistrictData, getSummaryReportData } from "./reportData";
import moment from "moment";

let delay = 0;
if(process.env.delay)
    delay = process.env.delay;

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerReportApi {
    static getDistrictData(searchCriteria) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: config.apiUrl + 'district-users/?' + $.param(searchCriteria),
                crossDomain: true,
                dataType: "json",
                contentType: "application/json",
                type: "GET",
                statusCode: {
                    200: function (data) {
                        resolve(data);
                    },
                    400: function (data) {
                        reject("There was an error while attempting to fetch information - " + data.responseText);
                    },
                    500: function () {
                        reject("There was an error while attempting to fetch information.  Please try again.  If the problem persists, contact " + config.supportEmail + ".");
                    }
                }
            });
        });
    }

    static exportDistrictData(searchCriteria) {
        window.location.href = config.apiUrl + 'district-users/export/?' + $.param(searchCriteria);
    }

    static getSummaryReportData(searchCriteria) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: config.apiUrl + 'users/summary/?' + $.param(searchCriteria),
                beforeSend: function(request) {
                    request.setRequestHeader("Authorization", LocalStorage.getAuthTokenValue());
                },
                crossDomain: true,
                dataType: "json",
                contentType: "application/json",
                type: "GET",
                statusCode: {
                    200: function (data) {
                        resolve(data);
                    },
                    404: function() {
                        LocalStorage.logout();
                        reject("Your session has expired.  Please login again.  If the problem persists, contact " + config.supportEmail + ".");
                        window.location.href = "/";
                    },
                    400: function (data) {
                        reject("There was an error while attempting to fetch information - " + data.responseText);
                    },
                    500: function () {
                        reject("There was an error while attempting to fetch information.  Please try again.  If the problem persists, contact " + config.supportEmail + ".");
                    }
                }
            });
        });
    }
}


//************************************************** MOCK API SERVER CALLS ****************************************

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.

class MockReportApi {
    static getDistrictData(searchCriteria) {
        return new Promise((resolve) => {
            setTimeout(() => {
                let districtData = getDistrictData();

                if(searchCriteria.NameContains !== "")
                    districtData = districtData.filter(user => (user.FirstName + " " + user.LastName).includes(searchCriteria.NameContains));

                if(searchCriteria.DistrictId !== null)
                    districtData = districtData.filter(user => user.DistrictId === searchCriteria.DistrictId);

                let useDate = false;
                let sortProp = "LastName";
                switch (searchCriteria.OrderByColumn)
                {
                    case "First Name":
                        sortProp = "FirstName";
                        break;
                    case "Course Name":
                        sortProp = "CourseName";
                        break;
                    case "Completion Date":
                        sortProp = "CourseCompletionDate";
                        useDate = true;
                        break;
                }

                let sortDir = (searchCriteria.OrderDescending) ? -1 : 1;

                if(useDate)
                    districtData.sort(function(a,b) {
                        const first = moment((a[sortProp] != "" ) ? a[sortProp] : "1/1/1900");
                        const second = moment((b[sortProp] != "" ) ? b[sortProp] : "1/1/1900");
                        return (first > second) ? sortDir : ((second > first) ? -1 * sortDir : 0);
                    } );
                else
                    districtData.sort(function(a,b) {return (a[sortProp] > b[sortProp]) ? sortDir : ((b[sortProp] > a[sortProp]) ? -1 * sortDir : 0);} );

                const skip = (searchCriteria.Page - 1) * searchCriteria.RecordsPerPage;
                const take = searchCriteria.RecordsPerPage + skip;

                resolve(Object.assign({}, { Data: districtData.slice(skip, take), TotalRecords: districtData.length}));
            }, delay);
        });
    }

    static exportDistrictData() {
        // no mock available as no way to create a mock Excel file
    }

    static getSummaryReportData(searchCriteria) {
        return new Promise((resolve) => {
            setTimeout(() => {
                let summaryData = getSummaryReportData();
                summaryData.DateRange = moment(searchCriteria.StartDate).format("MM/DD/YYYY") + " - " + moment(searchCriteria.EndDate).format("MM/DD/YYYY");
                resolve(Object.assign({}, summaryData));
            }, delay);
        });
    }
}

const ReportApi = (process.env.NODE_ENV === 'production' || process.env.API_MODE === 'server') ? ServerReportApi : MockReportApi;
export default ReportApi;