import $ from 'jquery';
import * as config from "../constants/config";
import LocalStorage from "../components/shared/localStorage";
import {getAccounts} from "./accountData";
import ApiUtility from "./apiUtility";
let delay = 0;
if(process.env.delay)
    delay = process.env.delay;

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerAuthenticateApi {
    static attemptLogin(loginViewModel) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: config.apiUrl + 'accounts/login',
                data: JSON.stringify(loginViewModel),
                crossDomain: true,
                dataType: "json",
                contentType: "application/json",
                type: "POST",
                error: function (data) {
                    new ApiUtility(data, reject).errorResponseHandler();
                },
                success: function (data) {
                    new ApiUtility({
                        access_token: data.AccessToken,
                        at_expires_in: data.ExpiresIn,
                        refresh_token: data.RefreshToken,
                        rt_expires_in: data.RefreshTokenExpiresIn,
                        isadmin: data.IsAdmin,
                        role: data.Role,
                        districtId: data.DistrictId
                    }, resolve).successResponseHandler();
                }
            });
        });
    }

    static refreshToken(refreshToken) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: config.apiUrl + 'accounts/refresh-token',
                data: {refreshToken: refreshToken},
                crossDomain: true,
                dataType: "json",
                contentType: "application/json",
                type: "GET",
                error: function (data) {
                    new ApiUtility(data, reject).errorResponseHandler();
                },
                success: function (data) {
                    new ApiUtility({
                        access_token: data.AccessToken,
                        at_expires_in: data.ExpiresIn,
                        refresh_token: data.RefreshToken,
                        rt_expires_in: data.RefreshTokenExpiresIn,
                        isadmin: data.IsAdmin,
                        role: data.Role,
                        districtId: data.DistrictId
                    }, resolve).successResponseHandler();
                }
            });
        });
    }

    static getUserTokenAsAdmin(userId) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: config.apiUrl + 'accounts/token/' + userId,
                beforeSend: function(request) {
                    request.setRequestHeader("Authorization", LocalStorage.getAuthTokenValue());
                },
                crossDomain: true,
                dataType: "json",
                contentType: "application/json",
                type: "GET",
                error: function (data) {
                    new ApiUtility(data, reject).errorResponseHandler();
                },
                success: function (data) {
                    new ApiUtility({
                        access_token: data.AccessToken,
                        at_expires_in: data.ExpiresIn,
                        refresh_token: data.RefreshToken,
                        rt_expires_in: data.RefreshTokenExpiresIn,
                        isadmin: data.isadmin,
                        role: data.role,
                        districtId: data.DistrictId
                    }, resolve).successResponseHandler();
                }
            });
        });
    }
}


//************************************************** MOCK API SERVER CALLS ****************************************

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.

class MockAuthenticateApi {
    static attemptLogin(loginViewModel) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                let userAcct = getAccounts().filter(account =>
                    account.Email === loginViewModel.Email &&
                    account.Password === loginViewModel.Password);

                if (userAcct.length === 0)
                    reject("There was an error while attempting to login.");
                else {
                    let convertToMilliseconds = config.mockAtExpiresInSeconds * 1000;
                    const at_expiration = new Date().getTime() + convertToMilliseconds;

                    convertToMilliseconds = config.mockRtExpiresInSeconds * 1000;
                    const rt_expiration = new Date().getTime() + convertToMilliseconds;

                    resolve({
                        access_token: userAcct[0].Id.toString(),
                        at_expires_in: at_expiration,
                        refresh_token: userAcct[0].Id.toString(),
                        rt_expires_in: rt_expiration,
                        isadmin: userAcct[0].isadmin,
                        role: userAcct[0].Role
                    });
                }
            }, delay);
        });
    }
    static refreshToken(refreshToken) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                let userAcct = getAccounts().filter(account => account.Id === parseInt(refreshToken));

                if (userAcct.length === 0)
                    reject("There was an error while attempting to retrieve your account.");
                else {

                    let convertToMilliseconds = config.mockAtExpiresInSeconds * 1000;
                    const at_expiration = new Date().getTime() + convertToMilliseconds;

                    convertToMilliseconds = config.mockRtExpiresInSeconds * 1000;
                    const rt_expiration = new Date().getTime() + convertToMilliseconds;

                    resolve({
                        access_token: userAcct[0].Id.toString(),
                        at_expires_in: at_expiration,
                        refresh_token: userAcct[0].Id.toString(),
                        rt_expires_in: rt_expiration,
                        isadmin: userAcct[0].isadmin,
                        role: userAcct[0].Role
                    });
                }
            }, delay);
        });
    }

    static getUserTokenAsAdmin(userId) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                let userAcct = getAccounts().filter(account => account.Id === userId);

                if (userAcct.length === 0)
                    reject("There was an error while attempting to retrieve your account.");
                else {

                    let convertToMilliseconds = config.mockAtExpiresInSeconds * 1000;
                    const at_expiration = new Date().getTime() + convertToMilliseconds;

                    convertToMilliseconds = config.mockRtExpiresInSeconds * 1000;
                    const rt_expiration = new Date().getTime() + convertToMilliseconds;

                    resolve({
                        access_token: userAcct[0].Id.toString(),
                        at_expires_in: at_expiration,
                        refresh_token: userAcct[0].Id.toString(),
                        rt_expires_in: rt_expiration,
                        isadmin: userAcct[0].isadmin,
                        role: userAcct[0].Role
                    });
                }
            }, delay);
        });
    }

}

const AuthenticateApi = (process.env.NODE_ENV === 'production' || process.env.API_MODE === 'server') ? ServerAuthenticateApi : MockAuthenticateApi;
export default AuthenticateApi;