import React from 'react';
import PropTypes from "prop-types";
import TextField from '../common/inputs/textField';
import Button from '../common/buttons/button';
import ButtonLink from '../common/buttons/buttonLink';
import { Link } from "react-router-dom";

const LoginForm = ({ loginViewModel, onClick, onCancel, onChange, isLoading, errors }) => {
    return (
        <div>
            <p>Enter your password to access the training.</p>

            <form className="one-col-condensed-form">
                <span><strong>{loginViewModel.Email}</strong></span>

                <hr className="spacer" />

                <TextField
                    inputType="password"
                    name="Password"
                    label="Password: "
                    showLabel
                    value={loginViewModel.Password}
                    onChange={onChange}
                    inputClass="input-password"
                    error={(errors) ? errors.Password: ""}
                    maxLength={50}
                />

                <div className="row text-align-right">
                    <ButtonLink name="btnCancel" onClick={onCancel} label="Cancel" />

                    <Button
                        name="btnLogin"
                        label="Login"
                        onClick={onClick}
                        btnClass="input-green"
                        disabled={isLoading}
                    />
                </div>
                <div className="row">
                    <hr />
                </div>
                <div className="row text-align-right">
                    <Link className="button-link" to="ForgotPassword">Forgot password?</Link>
                </div>
            </form>
        </div>
    );
};

LoginForm.propTypes = {
    loginViewModel: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    errors: PropTypes.object
};

export default LoginForm;
