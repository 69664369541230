import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as layoutActions from '../../actions/layoutActions';
import * as reportActions from "../../actions/reportActions";
import { Link } from "react-router-dom";
import {checkUserPermissions} from "../../components/authenticate/allow";
import localStorage from "../../components/shared/localStorage";
import * as PolicyEvents from "../../constants/policyEvents";
import * as reportConstants from "../../constants/reportConstants";
import Toastr from "toastr";
import ToastrOptions from "../../constants/toastr";
import {createSelectListObject} from "../../components/shared/selectListHelpers";
import DistrictReportSearchForm from "../../components/reports/districtReportSearchForm";
import DistrictReportResults from "../../components/reports/districtReportResults";
import PushFocusToElement from "../../components/common/pushFocusToElement";

export class DistrictReport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            generalProfileData: {
                districts: []
            },
            errors: {}
        };

        Toastr.options = ToastrOptions.Error();

        this.generateReport = this.generateReport.bind(this);
        this.clearCriteria = this.clearCriteria.bind(this);
        this.order = this.order.bind(this);
        this.first = this.first.bind(this);
        this.last = this.last.bind(this);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.onCriteriaChange = this.onCriteriaChange.bind(this);
        this.onExportClick = this.onExportClick.bind(this);
    }

    componentWillMount() {
        this.props.actions.updatePageTitle("District Report");
        if (this.props.searchCriteria.searchPerformed) {
            this.props.actions.loadDistrictData(this.props.searchCriteria)
                .then(() => {
                    PushFocusToElement("NameEmailContains");
                })
                .catch(error => {
                    Toastr.error(error);
                });
        }
        else
            PushFocusToElement("NameEmailContains");

        this.updateGeneralProfileData(this.props);
    }

    componentWillReceiveProps(newProps){
        this.updateGeneralProfileData(newProps);
    }

    updateGeneralProfileData(newProps) {
        let allLoaded = true;
        let needsLoading = false;
        const generalProfileData = this.state.generalProfileData;
        ["districts"].map( generalDataName => {
            if (this.state.generalProfileData[generalDataName].length === 0 &&
                newProps.generalProfileData &&
                newProps.generalProfileData[generalDataName].length > 0) {
                const selectListItems = newProps.generalProfileData[generalDataName].map(
                    item => createSelectListObject(generalDataName, item)
                );
                needsLoading = true;

                generalProfileData[generalDataName] = selectListItems;
            }
            else if(this.state.generalProfileData[generalDataName].length === 0)
                allLoaded = false;
        });
        if(needsLoading) {
            this.setState({generalProfileData: generalProfileData});

            if (allLoaded)
                PushFocusToElement("NameEmailContains");
        }
    }
    onCriteriaChange(event) {
        let districtSearchCriteria = Object.assign({}, this.props.searchCriteria);
        const name = event.target.name;

        districtSearchCriteria[name] = event.target.value;

        this.props.actions.saveDistrictReportSearchCriteria(districtSearchCriteria);
    }

    onExportClick(event) {
        event.preventDefault();

        let criteria = Object.assign({}, this.props.searchCriteria);

        this.loadDistrictData(criteria, true);
    }

    generateReport(event) {
        event.preventDefault();

        let criteria = Object.assign({}, this.props.searchCriteria);
        criteria.Page = 1;

        this.loadDistrictData(criteria);
    }

    clearCriteria(event) {
        event.preventDefault();

        this.props.actions.saveDistrictReportSearchCriteria(reportConstants.generateEmptyDistrictSearchCriteria());
    }

    order(event) {
        event.preventDefault();

        const target = event.target;

        let criteria = Object.assign({}, this.props.searchCriteria);

        if (criteria.OrderByColumn === target.innerHTML) {
            criteria.OrderDescending = !criteria.OrderDescending;
        }
        else {
            criteria.OrderDescending = false;
            criteria.OrderByColumn = target.innerHTML;
        }

        this.loadDistrictData(criteria);
    }

    first(event) {
        event.preventDefault();

        let criteria = Object.assign({}, this.props.searchCriteria);
        criteria.Page = 1;

        this.loadDistrictData(criteria);
    }

    last(event) {
        event.preventDefault();

        const totalRecords = this.props.totalRecords;

        const lastPage = Math.ceil(totalRecords / this.props.searchCriteria.RecordsPerPage);

        let criteria = Object.assign({}, this.props.searchCriteria);
        criteria.Page = lastPage;

        this.loadDistrictData(criteria);
    }

    next(event) {
        event.preventDefault();

        let criteria = Object.assign({}, this.props.searchCriteria);
        criteria.Page = criteria.Page + 1;

        this.loadDistrictData(criteria);
    }

    previous(event) {
        event.preventDefault();

        let criteria = Object.assign({}, this.props.searchCriteria);
        criteria.Page--;
        this.loadDistrictData(criteria);
    }

    loadDistrictData(searchCriteria, isExport = false) {
        searchCriteria.searchPerformed = true;
        searchCriteria.DistrictId = null;

        if(!checkUserPermissions(PolicyEvents.USE_DISTRICT_ADMIN_FILTERS)) {
            const auth = localStorage.getAuthentication();
            searchCriteria.DistrictId = auth.districtId;
        }

        this.props.actions.saveDistrictReportSearchCriteria(searchCriteria);

        if(isExport) {
            this.props.actions.exportDistrictData(searchCriteria);
        }
        else {
            this.props.actions.loadDistrictData(searchCriteria)
                .catch(error => {
                    Toastr.error(error);
                });
        }
    }

    render() {
        return (
            <div>
                {this.props.authentication.isAdmin &&
                <div className={"is-print-hidden"}>
                    <p><Link to="/admin/menu">Return to Admin Menu</Link></p>
                </div>
                }
                <div className={"is-print-hidden"}>
                    <p>Specify any options and click "Search".  Then, click "Export" to generate an Excel file of the search results.</p>
                </div>
                <hr />
                <DistrictReportSearchForm criteria={this.props.searchCriteria}
                                          generalProfileData={this.state.generalProfileData}
                                          onFormChange={this.onCriteriaChange}
                                          clearCriteria={this.clearCriteria}
                                          generateReport={this.generateReport}
                                          errors={this.state.errors}
                />
                {this.props.searchCriteria.searchPerformed &&
                    <div>
                        <hr />
                        <DistrictReportResults districtData={this.props.districtData}
                                               first={this.first}
                                               last={this.last}
                                               next={this.next}
                                               previous={this.previous}
                                               order={this.order}
                                               totalRecords={this.props.totalRecords}
                                               currentPage={this.props.searchCriteria.Page}
                                               recordsPerPage={this.props.searchCriteria.RecordsPerPage}
                                               onExportClick={this.onExportClick}/>
                        <hr />
                        {this.props.authentication.isAdmin &&
                            <p className={"is-print-hidden"}><Link to="/admin/menu" className="button-link">Return to Admin
                                Menu</Link></p>
                        }
                    </div>
                }
            </div>
        );
    }
}

DistrictReport.propTypes = {
    districtData: PropTypes.arrayOf(PropTypes.object).isRequired,
    searchCriteria: PropTypes.object.isRequired,
    generalProfileData: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    ajaxCallsLoading: PropTypes.bool,
    authentication: PropTypes.object.isRequired,
    totalRecords: PropTypes.number
};

function mapStateToProps(state) {
    return {
        districtData: state.districtReport.searchResults,
        searchCriteria: state.districtReport.searchCriteria,
        totalRecords: state.districtReport.totalRecords,
        generalProfileData: state.generalProfileData,
        ajaxCallsLoading: state.ajaxCallsInProgress > 0,
        authentication: state.authentication
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign({}, reportActions, layoutActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DistrictReport);