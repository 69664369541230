function createSelectListObject(generalDataName, item) {
   if (generalDataName === "roles") {
        return {
            value: item.RoleId,
            text: item.RoleName
        };
    }
    else if (generalDataName === "districts") {
        return {
            value: item.DistrictId,
            text: item.DistrictName
        };
    }
}

export {createSelectListObject};
