export const supportEmail = "support+LMT@fcim.org";
export const defaultRecordsPerPage = 10;

export const apiUrl = (process.env.NODE_ENV === 'production') ? "/api/" : "http://localhost:5000/api/";

export const mockAtExpiresInSeconds = 900;
export const mockRtExpiresInSeconds = 5400;

export const courseLandingPage = (process.env.NODE_ENV === 'production') ? "/courses/LMT/index.html" : "/course";
export const adminLandingPage = "/admin/menu";

export const useRefreshToken = "useRefreshToken";