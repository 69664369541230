import React from "react";
import PropTypes from "prop-types";
import TextField from "../common/inputs/textField";
import SelectField from "../common/inputs/selectField";
import Button from "../common/buttons/button";
import ButtonLink from "../common/buttons/buttonLink";
import ButtonBar from "../common/buttons/buttonBar";
import GridColumn from "../common/gridColumn";
import GridRow from "../common/gridRow";
import * as PolicyEvents from "../../constants/policyEvents";
import Allow from "../../containers/authenticate/allow";
import {generateEmployeeTypeOptions} from "../../constants/userConstants";

const SearchUserForm = ({criteria, generalProfileData, onFormChange, clearCriteria, search}) => {
    const districts = generalProfileData.districts.map(m => { return { value: m.value, text: m.text}; });
    return (
        <form>
            <GridRow rowClass="filter-bar" medium="2" large="4">
                <div>
                    <GridColumn>
                        <TextField name="NameEmailContains"
                                   label="Name or Email"
                                   onChange={onFormChange}
                                   showLabel
                                   value={criteria.NameEmailContains}
                                   maxLength={100}
                        />
                    </GridColumn>
                    <GridColumn>
                        <SelectField name="EmployeeType"
                                     label="Type"
                                     onChange={onFormChange}
                                     showLabel
                                     options={generateEmployeeTypeOptions()}
                                     value={criteria.EmployeeType}
                                     defaultOption={"Any Type"}
                        />
                    </GridColumn>
                    <GridColumn>
                        <SelectField name="DistrictId"
                                     label="District"
                                     onChange={onFormChange}
                                     showLabel
                                     options={districts}
                                     value={criteria.DistrictId}
                                     defaultOption={"Any District"}
                        />
                    </GridColumn>
                    <Allow policyEvent={PolicyEvents.MANAGE}>
                        <GridColumn>
                            <SelectField name="RoleId"
                                         label="Role"
                                         onChange={onFormChange}
                                         showLabel
                                         options={generalProfileData.roles}
                                         value={criteria.RoleId}
                                         defaultOption={"Any Role"}/>
                        </GridColumn>
                    </Allow>
                </div>
                <div>
                    <ButtonBar>
                        <Button name="btnSearchUsers" onClick={search} label="Search"/>
                        <ButtonLink name="btnClearUserSearch" onClick={clearCriteria} label="Clear"/>
                    </ButtonBar>
                </div>
            </GridRow>
        </form>
    );
};

SearchUserForm.propTypes = {
    criteria: PropTypes.object.isRequired,
    generalProfileData: PropTypes.object.isRequired,
    onFormChange: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    clearCriteria: PropTypes.func.isRequired
};

export default SearchUserForm;