function profileFormIsValid(accountViewModel, errors) {
    let formIsValid = true;

    const { Email, FirstName, LastName, UpdatePassword, Password, VerifyPassword,  IsDistrictEmployee, DistrictId, IsOther} = accountViewModel;

    if(Email !== undefined && Email.trim().length === 0) {
        errors.Email = 'Email is required';
        formIsValid = false;
    }

    if(FirstName !== undefined && FirstName.trim().length === 0) {
        errors.FirstName = 'First name is required';
        formIsValid = false;
    }

    if(LastName !== undefined && LastName.trim().length === 0) {
        errors.LastName = 'Last name is required';
        formIsValid = false;
    }

    if(UpdatePassword === undefined || UpdatePassword) {
        if (Password.trim().length === 0) {
            errors.Password = 'Password is required.';
            formIsValid = false;
        }

        if (VerifyPassword.trim().length === 0) {
            errors.VerifyPassword = 'Verify Password is required.';
            formIsValid = false;
        }
        else if (Password.trim() !== VerifyPassword.trim()) {
            errors.VerifyPassword = 'Password does not match the verified password.';
            formIsValid = false;
        }
    }

    if(IsDistrictEmployee === null && IsOther === null) {
        errors.EmployeeType = 'Type is required';
        formIsValid = false;
    }

    if(IsDistrictEmployee === 1 && DistrictId === null) {
        errors.DistrictId = 'District is required';
        formIsValid = false;
    }

    return formIsValid;
}
export default profileFormIsValid;