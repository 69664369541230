import * as config from "./config";

export function generateEmptyUserSearchCriteria() {
    return {
        RoleId: null,
        DistrictId: null,
        EmployeeType: null,
        NameEmailContains: "",
        Page: 1,
        RecordsPerPage: config.defaultRecordsPerPage,
        OrderByColumn: "Last Name",
        OrderDescending: false,
        searchPerformed: false
    };
}

export function generateEmployeeTypeOptions() {
    return [
        { value: "1", text: "School district employee" },
        { value: "2", text: "Other" },
    ];
}